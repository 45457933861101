// NAVBAR
document.addEventListener('scroll', ()=>{
    let navbar = document.querySelector('#nav2')
    if (window.pageYOffset > 60) {
        navbar.classList.add('border-navbar')
    }
    else {
        navbar.classList.remove('border-navbar')
    }
} )

$(".rotate").click(function(){
    $(this).toggleClass("down"); 
});